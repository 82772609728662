@font-face {
    font-family: "LeagueSpartan";
    src: url("./assets/fonts/LeagueSpartan-Bold.otf");
    font-weight: 400;
    font-style: normal;
  }
  
  main {
    --grey-color: #38606B, 
    --almond-color: #EBD3BC,
    --green-color: #103842
  }


.container {
  border: 2px solid #38606B;
  flex: 1;

}