@font-face {
  font-family: "LeagueSpartan";
  src: url("./assets/fonts/LeagueSpartan-Bold.otf");
  font-weight: 400;
  font-style: normal;
}

main {
  --grey-color: #38606B, 
  --almond-color: #EBD3BC,
  --green-color: #103842
}

/* LINK FOR COLOR PALLETTE  https://coolors.co/38606b-ebd3bc-103842 */

html {
  background-color: #38606B;
}

.App {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #38606B;
  color: #EBD3BC;


}

.about-section-image-div {
  /* display: flex; */
  width: 40%;
  justify-content: center;
  align-items: center;
}

.about-section-image {
  /* height: 100%; */
  /* width: 100%; */
  /* max-height: 100%; */
  max-width: 100%;
  /* aspect-ratio: 1004/1025; */

}

.about-section-top-div {
  width: 80%;
  height: 40%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}
.about-section-bottom-div {
  /* height: 2000px; */
  /* padding-top: -500px; */
  width: 80%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.about-section-school-div {
  display: flex;
  width: 36%;
  /* background-color: blue; */
  /* align-items: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-top: 2px solid #EBD3BC;
}

.about-section-title {
  color: #EBD3BC;
}

.about-section-text {
  max-width: 100%;
  word-wrap: break-word;
  color: #EBD3BC;
}

.about-section-text-div {
  padding-top: -30%;
  /* flex: 1; */
  width: 40%;
  /* background-color: blue;  */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.about-section-stanford-logo {
  height: 20em;

}

body {
  font-family: "LeagueSpartan", sans-serif;
  overflow-x: hidden;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1.5rem;
}

.contact-element-button {
  background-color: #38606B;
  width: 15%;
  /* height: 25%; */
  font-size: 1rem;
  color: #EBD3BC;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 1.75%;
  text-align: center;
  text-decoration: none;
}

.contact-elements-div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

}

.contact-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contact-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #EBD3BC;
  padding-bottom: 2.5%;
  color: #38606B;
}

.contact-section-title {
  color: #38606B;
}

.home-name-div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section {
  background-image: url("./assets/images/palisades-sunset.jpg");
  height: 35rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #38606B;
}


.job-card {
  border: 2px solid #38606B;
  width: 33%;
}

.job-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-card-body-smaller {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-card-button {
  background-color: #38606B;
  width: 60%;
  /* height: 25%; */
  font-size: 1.5rem;
  color: #EBD3BC;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 2.5%;
}

.job-card-modal{
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-card-modal-box {
  /* position: 'absolute';
  top: 50%;
  left: 50%; */
  width: 75%;
  /* height: 75%; */
  min-height: 60%;
  /* transform: translate(-50%, -50%); */
  background-color: 'background.paper';
  border: 2px solid #000;
  background-color: black;
  text-align: center;
  color: white;
}

.job-card-modal-description {
  font-size: 1.5em;
  padding-left: 1%;
  padding-right: 1%;
}

.job-card-modal-list {
  text-align: left;
  font-size: 1.25em;
}

.resume-button {
  display: flex;
  background-color: #EBD3BC;
  width: 25%;
  height: 2.5em;
  font-size: 1.5rem;
  color: #38606B;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5%;
}

.nav-header-element {
  text-decoration: none;
  color: #EBD3BC;
  /* border: 2px solid #EBD3BC; */
  /* padding: 5px 10px 5px 10px;
  border-radius: 10px; */
  text-align: center;
  justify-content: center;
  align-items: center;
}

.projects-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.project-card {
  border: 2px solid #EBD3BC;
}

.project-card-body {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5%;
}

.project-card-button {
  background-color: #EBD3BC;
  width: 60%;
  /* height: 25%; */
  font-size: 1.5rem;
  color: #38606B;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 2.5%;
}

.project-card-description {
  text-align-last: center;
  text-align: center;
}

.project-card-title {
  text-align: center;
  font-size: 2rem;
}

.projects-grid-container {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10%; 
  grid-row-gap: 5em; 
  padding-bottom: 2.5%;
  
}

.projects-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #38606B;
  color: #EBD3BC;
}


.topRightNavButtonsDiv {
  background-color: green;
  width: 70%;
}

.topNameTextDiv {
  background-color: purple;
  width: 30%;
}

.typewriter-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.work-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #EBD3BC;
  padding-bottom: 2.5%;
  color: #38606B;
  text-align: center;
}

.work-experiences-div {
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.work-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

}